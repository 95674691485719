var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees.employeesView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theEmployees",modifiers:{"theEmployees":true}}],class:_vm.currentPage == 'theEmployees' ? 'active' : ''},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theEmployees"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theEmployees"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                .employeesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employees' ? 'active' : '',attrs:{"to":"/employees"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theEmployees")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                .employeesView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employee-branches' ? 'active' : '',attrs:{"to":"/employee-branches"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employee-branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employee-branches")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
          .employeesAttendanceView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.attendance",modifiers:{"attendance":true}}],class:_vm.currentPage == 'attendance' ? 'active' : ''},[_vm._m(1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("attendanceAndDeparture"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"attendance"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance
                .employeesAttendanceAttendanceList
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'add-attendance' ? 'active' : '',attrs:{"to":"/add-attendance"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/add-attendance.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("checkIn")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance
                .employeesAttendanceDepatureList
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'add-departure' ? 'active' : '',attrs:{"to":"/add-departure"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/add-departure.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("checkOut")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData
                .usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'attendance-history' ? 'active' : '',attrs:{"to":"/attendance-history"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/attendanceHistory.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("attendanceAndDepartureHistory")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeBranches.branchesView
      )
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'branches' ? 'active' : '',attrs:{"to":"/branches"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/branches.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("theBranches")))])])],1):_vm._e(),_c('li',[_c('router-link',{class:_vm.currentPage == 'points' ? 'active' : '',attrs:{"to":"/points"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/points.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("points")))])])],1),_c('li',[_c('router-link',{class:_vm.currentPage == 'reports' ? 'active' : '',attrs:{"to":"/reports"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reports.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reports")))])])],1),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.privileges",modifiers:{"privileges":true}}],class:_vm.currentPage == 'privileges' ? 'active' : ''},[_vm._m(2),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("privileges"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"privileges"}},[_c('ul',[_c('li',[_c('router-link',{class:_vm.currentSubPage == 'establishment-privileges' ? 'active' : '',attrs:{"to":"/establishment-privileges"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/establishment-privileges.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("privilegesMsgs.establishment-privileges")))])])],1)])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
          .additionalSettingsView
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],class:_vm.currentPage == 'settings' ? 'active' : ''},[_vm._m(3),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("settings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"settings"}},[_c('ul',[_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-establishment' ? 'active' : '',attrs:{"to":"/settings-establishment"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-establishment.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-establishment")))])])],1),(
            _vm.checkPrivilege(
              _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                .additionalSettingsView
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'settings-other' ? 'active' : '',attrs:{"to":"/settings-other"}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings-other.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings-other")))])])],1):_vm._e()])])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/attendance.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/privileges.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg")}})])}]

export { render, staticRenderFns }